import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthRoutes from "./components/AuthRoutes/AuthRoutes.component";
import Signup from "./components/SignUp/Signup";
import { AuthProvider } from "./Context/auth.context.jsx";
import ROUTES from "./helpers/ROUTES";
import Login from "./components/Login/Login";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.component";
import ShareAggregatorDetails from "./components/ShareAggregatorDetails";
import NetworkStatus from "./components/NetworkStatus/NetworkStatus";

function App() {
  return (
    <Router>
      <AuthProvider>
        <ShareAggregatorDetails />
        <NetworkStatus />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <Route path="/" component={AuthRoutes} />
        </Switch>
        <ScrollToTop />
      </AuthProvider>
    </Router>
  );
}

export default App;
