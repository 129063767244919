import React, { Dispatch, SetStateAction } from "react";
import { IbusinessInfo } from "./GetStartedBasicInfo";
import styles from "../../../GetStarted.module.scss";
import { postDataWithDotNet } from "../../../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { useHistory } from "react-router-dom";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import Loader from "../../../../../components/Loader/Loader.component";
import { MixPanel } from "../../../../../helpers/mixPanel";
import { decrypt } from "../../../../../helpers/encryptor";

interface BusinessInfoPreview {
  setLoading: Dispatch<SetStateAction<boolean>>;
  aggregatorDetails: any;
  setError: Dispatch<SetStateAction<boolean>>;
  businessRqBody: IbusinessInfo | null;
  setBusinessRqBody: Dispatch<SetStateAction<IbusinessInfo | null>>;
  setSuccess: Dispatch<SetStateAction<any>>;
  setViewPreview: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  resolveCountryName: string;
  resolveBusinessSectorName: string;
  handleNext: (() => void) | any;
  scrollToTop: (() => void) | any;
}

const GetStartedBasicInfoPreview: React.FC<BusinessInfoPreview> = ({
  setLoading,
  // aggregatorDetails,
  setError,
  businessRqBody,
  loading,
  setBusinessRqBody,
  setViewPreview,
  resolveCountryName,
  resolveBusinessSectorName,
  handleNext,
  setSuccess,
  scrollToTop,
}) => {
  const history = useHistory();
  const email = sessionStorage.getItem("email");
  const decryptedEmail = JSON.parse(decrypt(email));

  const BusinessInformationHandler = async () => {
    const BusinessInformationreqBody = {
      business_name: businessRqBody?.businessName,
      business_email: businessRqBody?.businessEmail,
      business_phone: businessRqBody?.businessPhoneNumber,
      sector_id: Number(businessRqBody?.businessSector),
      country_code: businessRqBody?.countryCode,
      business_address: businessRqBody?.businessAddress,
      business_description: businessRqBody?.businessPurpose,
      registration_number: String(businessRqBody?.registrationNumber),
      short_name: businessRqBody?.shortName,
      business_phone_dialcode: businessRqBody?.dialCode,
      state: businessRqBody?.businessState,
      City: businessRqBody?.businessCity,
      business_overview: businessRqBody?.customersInformation,
    };
    try {
      setLoading(true);
      setError(false);
      const res = await postDataWithDotNet(
        apiEndpoints.addAggregatorBusinessInfo,
        BusinessInformationreqBody,
        true,
      );
      setSuccess(res?.message);
      scrollToTop();
      setLoading(false);
      if (res.status === true) {
        MixPanel.track("Onboard", {
          distinct_id: `${decryptedEmail}`,
          product: "Spark",
          status: "Business Basic Info Submitted",
          step: "GetStartedBusinessBasicInfo.tsx",
        });
        setTimeout(() => {
          handleNext();
        }, 1000);

        setViewPreview(false);
        setTimeout(() => {
          setBusinessRqBody(null);
          setSuccess("");
        }, 3000);
      } else {
        setError(errorHandler(res));

        MixPanel.track("Onboard", {
          distinct_id: `${decryptedEmail}`,
          product: "Spark",
          status: "Failed",
          step: "GetStartedBusinessBasicInfo.tsx",
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "GetStartedBusinessInfoPreview.tsx",
        },
      });
      setLoading(false);
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));

        setTimeout(() => {
          setError(false);
        }, 5000);
      }
    }
  };

  return (
    <div>
      <div className="card  p-4 mb-2">
        <div className="card-body p-4">
          <div>
            <p className={styles.previewType}>Business Name</p>
            <p className={styles.previewText}>{businessRqBody?.businessName}</p>
          </div>
          <div>
            <p className={styles.previewType}>Business Email</p>
            <p className={styles.previewText}>
              {businessRqBody?.businessEmail}
            </p>
          </div>
          <div>
            <p className={styles.previewType}>Business Phone Number</p>
            <p className={styles.previewText}>
              {businessRqBody?.businessPhoneNumber}
            </p>
          </div>
          <div>
            <p className={styles.previewType}>
              What Sector is your business in
            </p>
            <p className={styles.previewText}>{resolveBusinessSectorName}</p>
          </div>

          <div>
            <p className={styles.previewType}>
              What country is your business located?
            </p>
            <p className={styles.previewText}>{resolveCountryName}</p>
          </div>
          <div>
            <p className={styles.previewType}>
              What state is your business located?
            </p>
            <p className={styles.previewText}>
              {businessRqBody?.businessState}
            </p>
          </div>
          <div>
            <p className={styles.previewType}>
              What city is your business located?
            </p>
            <p className={styles.previewText}>{businessRqBody?.businessCity}</p>
          </div>
          <div>
            <p className={styles.previewType}>Business Physical Address</p>
            <p className={styles.previewText}>
              {businessRqBody?.businessAddress}
            </p>
          </div>
          <div>
            <p className={styles.previewType}>Business Registration Numbers</p>
            <p className={styles.previewText}>
              {businessRqBody?.registrationNumber}
            </p>
          </div>
          <div>
            <p className={styles.previewType}>Business Short Name</p>
            <p className={styles.previewText}> {businessRqBody?.shortName}</p>
          </div>
          <div>
            <p className={styles.previewType}>What does your business do?</p>
            <p className={styles.previewText}>
              {businessRqBody?.businessPurpose}
            </p>
          </div>
          <div>
            <p className={styles.previewType}>Who are your customers?</p>
            <p className={styles.previewText}>
              {businessRqBody?.customersInformation}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className=" d-flex justify-content-start flex-wrap">
          <button
            className="btn advancly-btn btn-sm transition-3d-hover mt-2 mb-4"
            type="submit"
            onClick={BusinessInformationHandler}
            disabled={loading}
          >
            Save and Continue
            {loading && <Loader />}
          </button>
        </div>

        <button
          className="btn advancly-white-btn btn-sm transition-3d-hover mt-2 mb-4"
          type="button"
          onClick={() => {
            setViewPreview(false);
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default GetStartedBasicInfoPreview;
