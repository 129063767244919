import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import useAllEligibilityQuestions from "../../../../custom-hooks/useAllEligibilityQuestions";
import styles from "../../GetStarted.module.scss";
import EligibilityTestStepOne from "./EligibilityTestStepOne";
import EligibilityTestStepTwo from "./EligibilityTestStepTwo";
import advanclyLogo from "../../../../img/advancly_logo.svg";
import EligibilityTestStepThree from "./EligibilityTestStepThree";
import PageLoaderAnimation from "../../../../components/PageLoader/PageLoaderAnimation";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../../helpers/mixPanel";

export default function GetStartedEligibilityTest() {
  const [arrayOfAnswers, setArrayOfAnswers] = useState<any>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [generalEligbilityAnswers, setGeneralEligbilityAnswers] = useState();
  const [sparkEligbilityAnswers, setSparkEligbilityAnswers] = useState();
  const [embedEligbilityAnswers, setEmbedEligbilityAnswers] = useState();
  const [questionsAnswered, setQuestionsAnswered] = useState<any>([]);

  // eslint-disable-next-line
  const { data, status, error } = useAllEligibilityQuestions();

  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedEligibilityTest.jsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: GetStartedEligibilityTest.tsx`, {
      page: "GetStartedEligibilityTest.tsx - (Spark)",
    });
  }, []);

  const onOptionAnswerChanged = (e: any, id: any) => {
    const { name, value } = e.target;
    const remainingObjects = arrayOfAnswers.filter(
      (answer: any) => Number(answer.questionId) !== Number(name),
    );
    setArrayOfAnswers([
      ...remainingObjects,
      {
        questionId: Number(name),
        questionAnswerId: [Number(value)],
        comment: "",
      },
    ]);
  };
  const onTextAnswerChanged = (
    e: ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { name, value } = e.target;
    const remainingObjects = arrayOfAnswers.filter(
      (answer: { questionId: number }) =>
        Number(answer.questionId) !== Number(name),
    );
    setArrayOfAnswers([
      ...remainingObjects,
      {
        questionId: Number(name),
        questionAnswerId: [],
        comment: value,
      },
    ]);
  };

  const onMultipleAnswerChanged = (
    e: ChangeEvent<HTMLInputElement>,
    inputType: string,
  ) => {
    const { name, value } = e.target;
    if (inputType && inputType === "checkbox") {
      if (!questionsAnswered.includes(value)) {
        setQuestionsAnswered([...questionsAnswered, value]);
      } else {
        setQuestionsAnswered(questionsAnswered.filter((v: any) => v !== value));
      }

      const fileToEdit = arrayOfAnswers.find(
        (answer: { questionId: number }) =>
          Number(answer.questionId) === Number(name),
      );
      const remainingObjects = arrayOfAnswers.filter(
        (answer: any) => Number(answer.questionId) !== Number(name),
      );

      if (e.target.checked) {
        setArrayOfAnswers([
          ...remainingObjects,
          {
            questionId: Number(name),
            comment: "",
            questionAnswerId: fileToEdit
              ? [...fileToEdit.questionAnswerId, Number(value)]
              : [Number(value)],
          },
        ]);
      } else {
        setArrayOfAnswers([
          ...remainingObjects,
          {
            ...fileToEdit,
            questionAnswerId: [
              ...fileToEdit.questionAnswerId.filter(
                (answer: number) => Number(answer) !== Number(value),
              ),
            ],
          },
        ]);
      }
    }
  };

  if (status === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoaderAnimation />
      </div>
    );

  return (
    // <div className={styles.getStartedOuterLayout}>
    //   <OnboardingBackButton />
    //   <div className={`${styles.getStartedInnerLayout} ${styles.tiny}`}>
    //     <div className="d-flex justify-content-between align-items-center w-100 mb-4">
    //       <h3 className="page-title mb-0">Eligibility Test</h3>
    //       <div className={styles.step}>Step 2 of 3</div>
    //     </div>

    //     <div className="card w-100">
    //       <div className="card-body p-4">
    //         {(() => {
    //           switch (currentStep) {
    //             case 1:
    //               return (
    //                 <EligibilityTestStepOne
    //                   setCurrentStep={setCurrentStep}
    //                   status={status}
    //                   questions={data && data?.questions}
    //                   answers={arrayOfAnswers}
    //                   onOptionAnswerChanged={onOptionAnswerChanged}
    //                   onMultipleAnswerChanged={onMultipleAnswerChanged}
    //                   onTextAnswerChanged={onTextAnswerChanged}
    //                 />
    //               );
    //             case 2:
    //               return (
    //                 <EligibilityTestStepTwo
    //                   setCurrentStep={setCurrentStep}
    //                   status={status}
    //                   questions={data && data.questions}
    //                   answers={arrayOfAnswers}
    //                   onOptionAnswerChanged={onOptionAnswerChanged}
    //                   onMultipleAnswerChanged={onMultipleAnswerChanged}
    //                   onTextAnswerChanged={onTextAnswerChanged}
    //                 />
    //               );
    //             default:
    //               return null;
    //           }
    //         })()}
    //       </div>
    //     </div>
    //   </div>
    // </div>

    //THE OLD CODE BASE IS KEPT TEMPORARILY TILL THIS MODULE IS FULLY STABLE AND WELL TESTED

    <div className={`ml-5 mt-5  `}>
      <div className={styles.onboardingLogoLink}>
        <Link className={styles.sparkLogoLink} to="/">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
      </div>
      <div
        className={`${styles.newGetStartedInnerBusinessComponent} ${styles.tiny}`}
      >
        <div className=" w-100">
          <div className=" ">
            {(() => {
              switch (currentStep) {
                case 1:
                  // case 1 represents General Eligibility questions
                  return (
                    <EligibilityTestStepOne
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      status={status}
                      questions={data && data?.questions}
                      answers={arrayOfAnswers}
                      onOptionAnswerChanged={onOptionAnswerChanged}
                      onMultipleAnswerChanged={onMultipleAnswerChanged}
                      onTextAnswerChanged={onTextAnswerChanged}
                      generalEligbilityAnswers={generalEligbilityAnswers}
                      setGeneralEligbilityAnswers={setGeneralEligbilityAnswers}
                      questionsAnswered={questionsAnswered}
                      setQuestionsAnswered={setQuestionsAnswered}
                    />
                  );
                case 2:
                  // case 2 represents Spark  Eligibility questions
                  return (
                    <EligibilityTestStepTwo
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      status={status}
                      questions={data && data.questions}
                      answers={arrayOfAnswers}
                      onOptionAnswerChanged={onOptionAnswerChanged}
                      onMultipleAnswerChanged={onMultipleAnswerChanged}
                      onTextAnswerChanged={onTextAnswerChanged}
                      sparkEligbilityAnswers={sparkEligbilityAnswers}
                      setSparkEligbilityAnswers={setSparkEligbilityAnswers}
                      setGeneralEligbilityAnswers={setGeneralEligbilityAnswers}
                      questionsAnswered={questionsAnswered}
                    />
                  );
                case 3:
                  // case 3 represents Embed  Eligibility questions
                  return (
                    <EligibilityTestStepThree
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      status={status}
                      questions={data && data.questions}
                      answers={arrayOfAnswers}
                      onOptionAnswerChanged={onOptionAnswerChanged}
                      onMultipleAnswerChanged={onMultipleAnswerChanged}
                      onTextAnswerChanged={onTextAnswerChanged}
                      setEmbedEligbilityAnswers={setEmbedEligbilityAnswers}
                      embedEligbilityAnswers={embedEligbilityAnswers}
                      setGeneralEligbilityAnswers={setGeneralEligbilityAnswers}
                      setSparkEligbilityAnswers={setSparkEligbilityAnswers}
                      questionsAnswered={questionsAnswered}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
