import { Fragment, useEffect } from "react";
import styles from "./../SignUp.module.scss";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { postDataWithoutToken } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { yupValidators } from "../../../helpers/yupValidators";
import sparkImageOnboard from "../../../img/sparkOverview.svg";
import advanclyLogo from "../../../img/advancly_logo.svg";
import LoaderSignUp from "../../LoaderSignUp/LoaderSignUp";
import { appInsights } from "../../AppInsight/AppInsight";
import { MixPanel } from "../../../helpers/mixPanel";

const schema = yup.object().shape({
  personal_email: yupValidators.email,
});
const FirstSignUp = ({
  setLoading,
  setError,
  setStep,
  upDateState,
  loading,
  error,
}: any) => {
  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    MixPanel.track(`Page View: SignUp_BusinessEmail.tsx`, {
      page: "SignUp_BusinessEmail.tsx - (Spark)",
    });
  }, []);

  const watchEmail = watch("personal_email", false);
  const watchEmailIncludes = watchEmail.toString().includes("@" && ".");

  const onSubmit = async ({ personal_email }: { personal_email: string }) => {
    setError(false);
    setLoading(true);

    try {
      const reqBody = { email: personal_email, productDomain: "Spark" };
      const response = await postDataWithoutToken(
        apiEndpoints.verifyEmail,
        reqBody,
        true, // Boolean here means that this endpoint should call production URL only
      );
      setLoading(false);
      let personalEmail = { personalEmail: personal_email };
      Object.assign(response, personalEmail);
      upDateState(response);

      setStep(2);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "FirstSignUp.jsx" },
      });

      MixPanel.track("Sign Up", {
        distinct_id: `${personal_email}`,
        "date created": new Date().toLocaleString(),
        URL: window.location.href,
        product: "Spark",
        status: `Sign Up Failed: ${error.response?.data?.message ?? error.message}`,
        step: "Email Verification",
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <main role="main" className={`bg-white ${styles.signUpLayout}`}>
      <div className={styles.left}>
        <Link className={styles.sparkLogoLink} to="/login">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
        <Fragment>
          {/* Form section  */}
          <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <h3 className=" font-weight-bold mb-1 color-blue-hover-none">
              Business Email address
            </h3>
            <p className="font-weight-normal mb-4 color-dark-purple">
              Enter your business email address to continue
            </p>
            {error && <p className="alert alert-danger small mb-4">{error}</p>}

            <div className="row">
              <div className={` col-12  ${styles.iconEmail}`}>
                <CustomInputField
                  type="email"
                  maxLength={128}
                  ref={register}
                  name="personal_email"
                  placeholder="hello@ricknmorty.com"
                  label="Email address"
                  errors={errors?.personal_email}
                  disableAutoComplete={true}
                />

                {watchEmailIncludes && (
                  <i
                    className={`fa  fa-check-circle fa-solid ${styles.iconstick}`}
                  ></i>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
              <div>
                <span className="small text-muted">Got an account? </span>
                <Link to="/login" className="small color-mid-blue">
                  Sign In
                </Link>
              </div>

              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                disabled={loading}
              >
                Proceed
                {loading && <LoaderSignUp />}
              </button>
            </div>
            <div>
              <span className="bg-light-grey " style={{ fontSize: "12px" }}>
                By proceeding to the next step, I acknowledge that I have <br />{" "}
                read and agree to the terms and conditions outlined in <br />
                Advancly's
                <a
                  href="https://advancly.com/terms-conditions/"
                  rel="noreferrer"
                  className="pl-1 pr-1 color-mid-blue"
                >
                  Terms of Use
                </a>
                and
                <a
                  href="https://advancly.com/privacy-policy/"
                  rel="noreferrer"
                  className="pl-1 color-mid-blue"
                >
                  Privacy Policy.
                </a>
              </span>
            </div>
          </form>
        </Fragment>
      </div>

      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImageOnboard}
            alt="Login Illustration"
          />
          <h3 className={styles.imgHeading}>
            Discover endless business possibilities with Spark
          </h3>
          <p className={styles.imgParagraph}>
            Register now for fast, tailored credit. <br /> Start your financial
            journey today!
          </p>
          <div className={styles.highlightContainer}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default FirstSignUp;
