import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import moment from "moment";
import { useHistory, withRouter } from "react-router-dom";
import useRepaymentDetails from "../../custom-hooks/useRepaymentDetails";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { ChangeEvent, FormEvent, useEffect } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { MixPanel } from "../../helpers/mixPanel";

interface IrepaymentList {
  loanRef: string;
  borrowerName: string;
  productName: string;
  principal: number;
  principalPaid: number;
  interest: number;
  interestPaid: number;
  borrower_id: number;
  loan_due_date: string;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  dateDue: string;
}

function RepaymentList({
  dueLoansPreState,
  setDueLoansPreState,
  dueLoansState,
  count,
  setCount,
  setDueLoansState,
}: any) {
  const { data, status, error } = useRepaymentDetails(dueLoansPreState);
  let history = useHistory();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDueLoansState({ ...dueLoansState, [name]: value });
  };
  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setCount(null);
    setDueLoansPreState({ ...dueLoansState, pageNumber: 1 });
  };
  const handleClick = (props: number) => {
    history.push(`/repayments/${props}`);
  };
  useEffect(() => {
    if (data) {
      if (count === null) {
        setCount(data?.totalCount);
      }
    }
  }, [count, data, setCount]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "RepaymentList.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: CompletedRepayments.tsx`, {
      page: "CompletedRepayments.tsx - (Spark)",
    });
  }, []);

  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {status === "loading" ? (
        <div className="d-flex justify-content-center p-5">
          <PageLoader />
        </div>
      ) : (
        !error && (
          <div className="">
            <div className="d-flex justify-content-between align-items-center advancly-sub-wrapper">
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    {/* <div
                className="input-group mr-3"
                style={{ maxWidth: "200px", marginTop: "10px" }}
              >
                <input
                  type="search"
                  className="form-control"
                  placeholder="Loan Ref"
                  value={dueLoansState.loanReference}
                  name="loanReference"
                  onChange={(e) => handleChange(e)}
                />
              </div> */}
                    <div className="form-group mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <input
                          type="date"
                          name="fromDate"
                          className="form-control"
                          value={dueLoansState.fromDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <input
                          type="date"
                          className="form-control"
                          name="toDate"
                          value={dueLoansState.toDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn  mt-2"
                        value="submit"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </>
              <div className="d-flex align-items-center">
                <p className="pr-2">Showing:</p>
                <CustomSelectDropdown
                  onChange={(e: any) =>
                    setDueLoansPreState({
                      ...dueLoansState,
                      size: e.target.value,
                    })
                  }
                  value={dueLoansPreState.size}
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                </CustomSelectDropdown>
              </div>
            </div>
            <div className="advancly-sub-wrapper mt-3">
              <div className="table-responsive">
                <table className="table-style">
                  <thead className="">
                    <tr>
                      <th className="th-td-style th-style ">Reference</th>
                      <th className="th-td-style th-style ">Borrower</th>
                      <th className="th-td-style th-style ">Product</th>
                      <th className="th-td-style th-style ">Principal</th>
                      <th className="th-td-style th-style ">Principal Paid</th>
                      <th className="th-td-style th-style ">Interest</th>
                      <th className="th-td-style th-style ">Interest Paid</th>
                      <th className="th-td-style th-style ">Due Date</th>
                      <th className="th-td-style th-style ">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.data?.length ? (
                      data?.data?.map((data: IrepaymentList, id: number) => {
                        const {
                          loanRef,
                          borrowerName,
                          productName,
                          // repayment_amount,
                          principal,
                          principalPaid,
                          interest,
                          interestPaid,
                          borrower_id,

                          dateDue,
                          currency,
                        } = data;
                        return (
                          <tr key={id}>
                            <td className="th-td-style ">{loanRef}</td>
                            <td
                              className=" th-td-style "
                              style={{ width: "10%" }}
                            >
                              {borrowerName}
                            </td>
                            <td className="th-td-style ">{productName}</td>
                            {/* <td>{formatMoney(repayment_amount, currency)}</td> */}
                            <td className="th-td-style ">
                              {formatMoney(principal, currency)}
                            </td>
                            <td className="th-td-style ">
                              {formatMoney(principalPaid, currency)}
                            </td>
                            <td className="th-td-style ">
                              {formatMoney(interest, currency)}
                            </td>
                            <td className="th-td-style ">
                              {formatMoney(interestPaid, currency)}
                            </td>

                            <td style={{ minWidth: "120px" }}>
                              {moment(dateDue).format("DD-MM-YYYY")}
                            </td>
                            <td
                              className="color-mid-blue cursor-pointer th-td-style"
                              onClick={() => handleClick(borrower_id)}
                              style={{ minWidth: "120px" }}
                            >
                              View Details
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoTableDataAvailable />
                    )}
                  </tbody>
                </table>
              </div>
              <Paginator
                size={dueLoansPreState.size}
                page={dueLoansPreState.pageNumber}
                count={count}
                changeCurrentPage={(pageNumber) =>
                  setDueLoansPreState({ ...dueLoansState, pageNumber })
                }
              />
            </div>
          </div>
        )
      )}
    </div>
  );
}
//@ts-ignore
export default withRouter(RepaymentList);
