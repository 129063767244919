import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../GetStarted.module.scss";
import ROUTES from "../../../helpers/ROUTES";
import { ReactComponent as Thumbs } from "../../../svg/icons/thumbs-up.svg";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { MixPanel } from "../../../helpers/mixPanel";

export default function GetStartedFinalPage() {
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedFinalPage.jsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: GetStartedFinalPage.tsx`, {
      page: "GetStartedFinalPage.tsx - (Spark)",
    });
  }, []);
  return (
    <div className={styles.finalPage}>
      <div className={styles.thumb}>
        <Thumbs />
      </div>
      <h3 className="page-title">Well done!</h3>
      <div className={styles.text}>
        Your onboarding request has been sent for review. We will respond to you
        within 2-4 business days. Goodluck!
      </div>
      <Link
        to={ROUTES.GET_STARTED}
        className="text-underline  cursor-pointer color-mid-blue"
      >
        Back to Homepage
      </Link>
    </div>
  );
}
