import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Fragment } from "react";
import styles from "./SignUp.module.scss";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postDataWithoutToken } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import sparkImageOnboard from "../../img/sparkOverview.svg";

import advanclyLogo from "../../img/advancly_logo.svg";

import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import useWorldCountries from "../../custom-hooks/useWorldCountries";
import Loader from "../Loader/Loader.component";
import { appInsights } from "../AppInsight/AppInsight";
import { MixPanel } from "../../helpers/mixPanel";
const schema = yup.object().shape({
  countries: yup.string().required("Please select country"),
  more_business_details: yup
    .string()
    .required("Please enter business description"),
});
const SignUpWaitList = ({
  setLoading,
  setError,
  setStep,
  loading,
  error,
  reqBodyData,
  setReqBodyData,
}: any) => {
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    MixPanel.track(`Page View: SignUp_Waitlist.tsx`, {
      page: "SignUp_Waitlist.tsx - (Spark)",
    });
  }, []);

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useWorldCountries();

  const onSubmit = async ({ countries, more_business_details }: any) => {
    setError(false);
    setLoading(true);
    setSuccess(false);
    const reqBody = {
      ...reqBodyData,
      country_code: countries,
      description: more_business_details,
    };
    try {
      const response = await postDataWithoutToken(
        apiEndpoints.GLOBAL_WAITLIST,
        reqBody,
        true, // Boolean here means that this endpoint should call production URL only
      );

      MixPanel.track("Sign Up", {
        distinct_id: `${reqBodyData?.personal_email}`,
        "date created": new Date().toLocaleString(),
        URL: window.location.href,
        product: "Spark",
        status: "Sign Up Complete",
        step: "SignUp Wait List.tsx",
      });

      setTimeout(() => {
        setSuccess(response?.status); // check the endpoint to get the accurate parameters
        setLoading(false);
        setReqBodyData();
        reset();

        setTimeout(() => {
          history.push("/login");
          setSuccess(false);
        }, 2500);
      }, 2000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "SignUpWaitList.tsx" },
      });
      setSuccess(false);
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  return (
    <main role="main" className={`bg-white ${styles.signUpLayout}`}>
      <div className={styles.left}>
        <Link className={styles.sparkLogoLink} to="/login">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
        <div className={`${styles.onboardingBackButton} mt-3`}>
          {" "}
          <small
            className="color-mid-blue  cursor-pointer font-weight-bold  "
            onClick={() => {
              setStep(3);
              setError(false);
            }}
          >
            &#8592; Go back
          </small>
        </div>
        <Fragment>
          {/* Form section  */}
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <h3 className=" font-weight-bold mb-1 color-blue-hover-none">
              Find out as soon as Advancly launches in your country{" "}
            </h3>
            <p className="font-weight-normal mb-4 color-dark-purple">
              Enter the following details to join our waitlist. You will be the
              first to know when we are in your country{" "}
            </p>
            {countriesError && (
              <p className="alert alert-danger small mb-4">
                {errorHandler(countriesError)}
              </p>
            )}
            {error && <p className="alert alert-danger small mb-4">{error}</p>}
            {success && (
              <p className="alert alert-success small mb-4">
                Thank you, submitted successfully
              </p>
            )}

            <div className="row">
              <div className={` col-12 `}>
                <label className="text-muted mb-0" htmlFor="countries">
                  Country
                </label>
                {isLoadingCountries && <Loader />}
                <select
                  className="form-control"
                  id="countries"
                  name="countries"
                  ref={register}
                  style={{ borderRadius: "10px" }}
                >
                  <option value=""> Select Country</option>
                  {countries?.map(({ name, id, iso2 }: any) => {
                    return (
                      <option value={iso2} key={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {errors?.countries && (
                  <ErrorHandler errors={errors.countries} />
                )}
              </div>
              <div className={` col-12 mt-2 `}>
                <label
                  className="text-muted mb-0"
                  htmlFor="more_business_details"
                >
                  What does your business do?
                </label>
                <textarea
                  id="more_business_details"
                  className="form-control"
                  name="more_business_details"
                  ref={register}
                  rows={4}
                  cols={50}
                  placeholder="Tell us about your business"
                  style={{ borderRadius: "10px" }}
                ></textarea>
                {errors?.more_business_details && (
                  <ErrorHandler errors={errors.more_business_details} />
                )}
              </div>
            </div>
            <div className=" mt-5 d-flex justify-content-between align-items-center mb-3 flex-wrap">
              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                disabled={loading}
              >
                Notify Me
                {loading && <LoaderSignUp />}
              </button>
            </div>
          </form>
        </Fragment>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImageOnboard}
            alt="Login Illustration"
          />
          <h3 className={styles.imgHeading}>
            Discover endless business possibilities with Spark
          </h3>
          <p className={styles.imgParagraph}>
            Register now for fast, tailored credit. <br /> Start your financial
            journey today!
          </p>
          <div className={styles.highlightContainer}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignUpWaitList;
