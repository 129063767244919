import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupValidators } from "../../../../../helpers//yupValidators";
import CustomSelectDropdown from "../../../../../components/CustomHTMLElements/CustomSelectDropdown";
import useWorldCountries from "../../../../../custom-hooks/useWorldCountries";
import CustomTextArea from "../../../../../components/CustomHTMLElements/CustomTextArea";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { getData, postData, putData } from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { useQueryCache } from "react-query";
import styles from "../../../GetStarted.module.scss";
import Loader from "../../../../../components/Loader/Loader.component";
import useGovernmentDocumentType from "../../../../../custom-hooks/useGovernmentDocumentType";
import { convertToBase64 } from "../../../../../helpers/convertToBase64";
import { useAuth } from "../../../../../Context/auth.context";
import CommentsBoardOfDirectorDocsModal from "./CommentsBoardOfDirectorDocsModal";
import { setTimeout } from "timers";
import LoaderColored from "../../../../../components/Loader/LoaderColored";

const schema = yup.object().shape({
  firstname: yupValidators.firstName,
  lastname: yupValidators.lastName,
  email: yupValidators.email,
  role: yup.string().required("Please enter role"),
  academicQualifications: yup
    .string()
    .required("Please enter academic qualifications"),
  periodOnCompanyBoard: yup
    .number()
    .positive()
    .required("Please enter period on company board"),
  workExperience: yup.string().required("Please enter your work experience"),
  country: yupValidators.countryCode,
  governmentID: yup.string().required("Please select government ID"),
  experienceTotheRole: yup
    .string()
    .required("Please enter experience with relation to role"),
  bvnNationalId: yup.string().required("Please enter your ID number"),
});

const BoardOfDirectorsForm = ({
  isAddMoreDirectors,
  setIsAddMoreDirectors,
  isDirectorsComplete,
  handleNext,
  loading,
  setLoading,
  addAnotherLoading,
  setAddAnotherLoading,
  setError,
  setSuccess,
  editAndPopulateDirectorsForm,
  setEditAndPopulateDirectorsForm,
  scrollToTop,
}: any) => {
  const queryCache = useQueryCache();
  const [countryIso, setCountryIso] = useState<string | null>(null);
  const [govtDocTypevalue, setGovtDocTypeValue] = useState<string | null>(null);
  const [selectedGovtFile, setSelectedGovtFile] = useState<any>(null);
  const [selectedProofOfAddressFile, setSelectedProofOfAddressFile] =
    useState<any>(null);
  const [isGovtFileChosen, setisGovtFileChosen] = useState<boolean>(false);
  const [isProofOfAddressFileChosen, setisProofOfAddressFileChosen] =
    useState<boolean>(false);
  const [comments, setComments] = useState<string | null>(null);
  const { aggregatorDetails } = useAuth();

  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const proofAddressRef = useRef(null);
  const govtDocsRef = useRef(null);

  const handleAutoPopulate = async (
    Path: string,
    fileNameParams: string,
    refName: any,
  ) => {
    try {
      const filePath = Path;
      // Fetch the file from the URL
      const response = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${filePath}`,
        true,
      );
      const byteString = atob(response.base64_string);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uint8Array]);
      // Set the file input value
      if (refName?.current) {
        const fileName = fileNameParams;
        const file = new File([blob], fileName);
        // Create a FileList using DataTransfer object
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);

        if (refName.current) {
          (refName.current as any).files = dataTransfer.files;
        }
        if (refName === govtDocsRef) {
          setSelectedGovtFile(file);
          setisGovtFileChosen(true);
        }
        if (refName === proofAddressRef) {
          setSelectedProofOfAddressFile(file);
          setisProofOfAddressFileChosen(true);
        }
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "BoardOfDirectorsForm.tsx",
        },
      });
      console.error("Error while auto-populating file:", error);
    }
  };

  // await getData(fileUrl, true);

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useWorldCountries(true);

  const {
    data: governmentDocTypeData,
    isLoading: isLoadingDocData,
    error: DoctypeError,
  } = useGovernmentDocumentType();

  const resolveCountryIso = countries?.find(
    ({ iso2 }: { iso2: string }) => iso2 === countryIso,
  )?.iso2;
  const resolveGovernementDocValue = governmentDocTypeData?.find(
    ({ value }: { value: string }) => value === govtDocTypevalue,
  )?.name;
  const onAddMoreDirectorHandler = async (data: any) => {
    const {
      firstname,
      lastname,
      email,
      country,
      role,
      academicQualifications,
      bvnNationalId,
      governmentID,
      periodOnCompanyBoard,
      workExperience,
      experienceTotheRole,
    } = data;
    if (selectedGovtFile && selectedProofOfAddressFile) {
      const govtBase64 = await convertToBase64(selectedGovtFile);
      const proofOfAddressBase64 = await convertToBase64(
        selectedProofOfAddressFile,
      );
      const ReqBody = {
        first_name: firstname,
        last_name: lastname,
        country_code: country,
        role,
        email,
        educational_qualification: academicQualifications,
        identity_number: bvnNationalId,
        govt_identity_type: +governmentID,

        period_in_company_board: periodOnCompanyBoard,
        work_experience: workExperience,
        experience_relevant_to_role: experienceTotheRole,
        funder_person_type: 4,
        customer_user_documents: [
          {
            funder_people_document_type: 2,
            base64_file_string: govtBase64,

            filename_with_extension: selectedGovtFile.name,
          },
          {
            funder_people_document_type: 1,
            base64_file_string: proofOfAddressBase64,
            filename_with_extension: selectedProofOfAddressFile.name,
          },
        ],
      };
      try {
        setAddAnotherLoading(true);
        setError(null);
        if (editAndPopulateDirectorsForm) {
          const res = await putData(
            apiEndpoints.FUNDER_PEOPLE,
            [{ ...ReqBody, id: editAndPopulateDirectorsForm?.id }],
            true,
          );
          setSuccess(res.message);
          scrollToTop();
        } else {
          const res = await postData(
            apiEndpoints.FUNDER_PEOPLE,
            [ReqBody],
            true,
          );
          setSuccess(res.message);
          scrollToTop();
        }
        setIsAddMoreDirectors(true);

        setAddAnotherLoading(false);
        queryCache.invalidateQueries([
          { funderId: aggregatorDetails?.aggregator_id, funderPeopleType: 4 },
          "getOnboardingManagement",
        ]);
        queryCache.invalidateQueries("");
        setEditAndPopulateDirectorsForm(null);
        reset();
        setSelectedGovtFile(null);
        setSelectedProofOfAddressFile(null);
        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      } catch (err) {
        appInsights.trackException({
          //@ts-ignore
          exception: err,
          properties: {
            fileName: "BoardOfDirectorsForm.tsx",
          },
        });
        setAddAnotherLoading(false);
        setSuccess(null);
        setError(errorHandler(err));
        scrollToTop();
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  };

  const onSaveContinueHandler = async (data: any) => {
    const {
      firstname,
      lastname,
      email,
      country,
      role,
      academicQualifications,
      bvnNationalId,
      governmentID,
      periodOnCompanyBoard,
      workExperience,
      experienceTotheRole,
    } = data;
    // Retrieve the uploaded file
    if (selectedGovtFile && selectedProofOfAddressFile) {
      let govtBase64 = await convertToBase64(selectedGovtFile);
      let proofOfAddressBase64 = await convertToBase64(
        selectedProofOfAddressFile,
      );

      const ReqBody = {
        first_name: firstname,
        last_name: lastname,
        country_code: country,
        role,
        email,
        educational_qualification: academicQualifications,
        identity_number: bvnNationalId,
        govt_identity_type: +governmentID,
        period_in_company_board: periodOnCompanyBoard,
        work_experience: workExperience,
        experience_relevant_to_role: experienceTotheRole,
        funder_person_type: 4,
        customer_user_documents: [
          {
            funder_people_document_type: 2,
            base64_file_string: govtBase64,

            filename_with_extension: selectedGovtFile.name,
          },
          {
            funder_people_document_type: 1,
            base64_file_string: proofOfAddressBase64,
            filename_with_extension: selectedProofOfAddressFile.name,
          },
        ],
      };

      try {
        setLoading(true);
        setError(null);
        if (editAndPopulateDirectorsForm) {
          const res = await putData(
            apiEndpoints.FUNDER_PEOPLE,
            [{ ...ReqBody, id: editAndPopulateDirectorsForm?.id }],
            true,
          );
          setSuccess(res.message);
          scrollToTop();
        } else {
          const res = await postData(
            apiEndpoints.FUNDER_PEOPLE,
            [ReqBody],
            true,
          );
          setSuccess(res.message);
          scrollToTop();
        }
        setIsAddMoreDirectors(false);

        setLoading(false);
        queryCache.invalidateQueries([
          { funderId: aggregatorDetails?.aggregator_id, funderPeopleType: 4 },
          "getOnboardingManagement",
        ]);
        queryCache.invalidateQueries("");
        setEditAndPopulateDirectorsForm(null);
        reset();
        setSelectedGovtFile(null);
        setSelectedProofOfAddressFile(null);
        setTimeout(() => {
          setSuccess(null);
          handleNext();
        }, 2000);
      } catch (err) {
        appInsights.trackException({
          //@ts-ignore
          exception: err,
          properties: {
            fileName: "BoardOfDirectorsForm.tsx",
          },
        });
        setLoading(false);
        setSuccess(null);
        setError(errorHandler(err));
        scrollToTop();
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (editAndPopulateDirectorsForm) {
      setValue("firstname", editAndPopulateDirectorsForm.first_name);
      setValue("lastname", editAndPopulateDirectorsForm.last_name);
      setValue("email", editAndPopulateDirectorsForm.email);
      setTimeout(() => {
        setValue("country", editAndPopulateDirectorsForm.country_code);
      }, 3000);
      setValue("role", editAndPopulateDirectorsForm.role);
      setValue(
        "academicQualifications",
        editAndPopulateDirectorsForm.educational_qualification,
      );
      setValue("bvnNationalId", editAndPopulateDirectorsForm.identity_number);

      setTimeout(() => {
        setValue(
          "governmentID",
          editAndPopulateDirectorsForm.govt_identity_type_id,
        );
      }, 4000);
      setValue(
        "periodOnCompanyBoard",
        editAndPopulateDirectorsForm.period_in_company_board,
      );
      setValue("workExperience", editAndPopulateDirectorsForm.work_experience);
      setValue(
        "experienceTotheRole",
        editAndPopulateDirectorsForm.experience_relevant_to_role,
      );
      if (editAndPopulateDirectorsForm?.government_id_file_path) {
        handleAutoPopulate(
          editAndPopulateDirectorsForm?.government_id_file_path,
          `government document ID.${editAndPopulateDirectorsForm?.government_id_file_path.slice(
            -3,
          )}`,
          govtDocsRef,
        );
      }
      if (editAndPopulateDirectorsForm?.proof_of_address_file_path) {
        handleAutoPopulate(
          editAndPopulateDirectorsForm?.proof_of_address_file_path,
          `proof of Address.${editAndPopulateDirectorsForm?.proof_of_address_file_path.slice(
            -3,
          )}`,
          proofAddressRef,
        );
      }
    }
  }, [editAndPopulateDirectorsForm, setValue]);

  return (
    <div className="py-4">
      {countriesError && (
        <p className="alert alert-danger small">
          {errorHandler(countriesError)}
        </p>
      )}
      {DoctypeError && (
        <p className="alert alert-danger small">{errorHandler(DoctypeError)}</p>
      )}
      <form>
        <div className="row">
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="firstname"
              placeholder="Enter your first name"
              label="First Name"
              errors={errors.firstname}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="lastname"
              placeholder="Enter your last name"
              label="Last Name"
              errors={errors.lastname}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="email"
              placeholder="Enter your email"
              label="Email"
              errors={errors.email}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomSelectDropdown
              ref={register}
              name="country"
              label="Country"
              errors={errors.country}
              showRequiredIcon={true}
              extraLabel={isLoadingCountries ? "(Loading...)" : ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCountryIso(e.target.value)
              }
            >
              <option value="">Choose Country</option>
              {countries?.map(({ name, id, iso2 }: any) => {
                return (
                  <option value={iso2} key={id}>
                    {name}
                  </option>
                );
              })}
            </CustomSelectDropdown>
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="role"
              placeholder="E.g. Chairman"
              label="Role"
              errors={errors.role}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="academicQualifications"
              placeholder="E.g. PH.D ...."
              label="Academic & Professional Qualifications"
              errors={errors.academicQualifications}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            {
              <CustomInputField
                type="number"
                maxLength={
                  resolveCountryIso === "NG" ||
                  editAndPopulateDirectorsForm?.country_code === "NG" ||
                  countryIso === "NG"
                    ? 11
                    : 15
                }
                ref={register}
                name="bvnNationalId"
                placeholder="345678908.."
                label={
                  resolveCountryIso === "NG" ||
                  editAndPopulateDirectorsForm?.country_code === "NG" ||
                  countryIso === "NG"
                    ? "Bvn"
                    : "National Identity Number"
                }
                errors={errors.bvnOrNationalId}
                showRequiredIcon={true}
              />
            }
          </div>
          <div className="col-12">
            <CustomSelectDropdown
              ref={register}
              name="governmentID"
              label="Select  Government ID type to upload"
              errors={errors.governmentID}
              showRequiredIcon={true}
              extraLabel={isLoadingDocData ? "Loading..." : ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setGovtDocTypeValue(e.target.value)
              }
            >
              <option value="">Select Government ID type for Upload</option>
              {governmentDocTypeData?.map(
                ({ name, id }: { id: number; name: string; value: string }) => {
                  return (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  );
                },
              )}
            </CustomSelectDropdown>
          </div>
          <div className="col-12">
            {/* This is for uploading government documents */}
            <div className="">
              <div className="d-flex align-items-center">
                <label className={`form-label text-muted`}>
                  {resolveGovernementDocValue
                    ? ` Upload ${resolveGovernementDocValue}`
                    : " Upload National ID Document"}
                </label>
                <sup className="ml-1 crimson">*</sup>
                {
                  <label className="form-label ml-2">
                    <small className="crimson">{`only PDF,JPEG,JPG and PNG accepted`}</small>
                  </label>
                }
              </div>
              <div
                className={` form-control ${styles.file_input} ${
                  isGovtFileChosen ? styles._chosen : ""
                }`}
              >
                <input
                  type="file"
                  ref={govtDocsRef}
                  name="governmentdocs"
                  accept=".pdf,.jpeg,.jpg,.png"
                  onChange={(e) => {
                    //@ts-ignore

                    setSelectedGovtFile(e.target.files[0]);
                    setisGovtFileChosen(true);
                  }}
                />
                <label className={`${styles.label_file}`}>
                  {selectedGovtFile?.name.length > 0
                    ? selectedGovtFile?.name.length >= 40
                      ? selectedGovtFile?.name.slice(0, 10)
                      : selectedGovtFile?.name
                    : "No file selected please select  file"}
                </label>

                <span className={`${styles.file_button}`}>
                  {" "}
                  {selectedGovtFile?.name?.length > 0 ? "Change" : "Choose"}
                </span>
              </div>
            </div>
            <div className="py-3">
              {editAndPopulateDirectorsForm?.government_id_approval_status ===
                1 && (
                <small className=" p-2 pending_status">
                  {" "}
                  Document Pending Approval
                </small>
              )}
              {editAndPopulateDirectorsForm?.government_id_approval_status ===
                2 && (
                <small className=" p-2 approved_status">
                  Documents Approved
                </small>
              )}
              {editAndPopulateDirectorsForm?.government_id_approval_status ===
                3 && (
                <small className="  p-2 declined_status ">
                  Document Declined
                </small>
              )}
              {editAndPopulateDirectorsForm && (
                <button
                  type="button"
                  className={`btn color-mid-blue  transition-3d-hover `}
                  data-toggle="modal"
                  data-target="#commentsBoardOfDirectorsModal"
                  onClick={() => {
                    setComments(editAndPopulateDirectorsForm?.govt_id_comment);
                  }}
                >
                  Comments{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chat-dots"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div className="col-12">
            {/* This is for uploading proof of address documents */}
            <div className="">
              <div className="d-flex align-items-center">
                <label
                  className={`form-label text-muted`}
                >{`Proof Of Address`}</label>
                <sup className="ml-1 crimson">*</sup>
                {
                  <label className="form-label ml-2">
                    <small className="crimson">
                      {"only PDF,JPEG,JPG & PNG accepted"}
                    </small>
                  </label>
                }
              </div>
              <div
                className={` form-control ${styles.file_input} ${
                  isProofOfAddressFileChosen ? styles._chosen : ""
                }`}
              >
                <input
                  type="file"
                  className={` ${styles.file_type}`}
                  ref={proofAddressRef}
                  id="myFile"
                  name="proofofaddressdocs"
                  accept=".pdf,.jpeg,.jpg,.png"
                  onChange={(e) => {
                    //@ts-ignore

                    setSelectedProofOfAddressFile(e.target.files[0]);
                    setisProofOfAddressFileChosen(true);
                  }}
                />

                <label className={`${styles.label_file}`}>
                  {selectedProofOfAddressFile?.name.length > 0
                    ? selectedProofOfAddressFile?.name.length >= 40
                      ? selectedProofOfAddressFile?.name.slice(0, 10)
                      : selectedProofOfAddressFile?.name
                    : "No file selected please select  file"}
                </label>

                <span className={`${styles.file_button}`}>
                  {selectedProofOfAddressFile?.name?.length > 0
                    ? "Change"
                    : "Choose"}
                </span>
              </div>
            </div>
            <div className="py-3">
              {editAndPopulateDirectorsForm?.proof_of_address_approval_status ===
                1 && (
                <small className=" p-2 pending_status">
                  {" "}
                  Document Pending Approval
                </small>
              )}
              {editAndPopulateDirectorsForm?.proof_of_address_approval_status ===
                2 && (
                <small className=" p-2 approved_status">
                  Documents Approved
                </small>
              )}
              {editAndPopulateDirectorsForm?.proof_of_address_approval_status ===
                3 && (
                <small className="  p-2 declined_status ">
                  Document Declined
                </small>
              )}

              {editAndPopulateDirectorsForm && (
                <button
                  type="button"
                  className={`btn color-mid-blue  transition-3d-hover `}
                  data-toggle="modal"
                  data-target="#commentsBoardOfDirectorsModal"
                  onClick={() => {
                    setComments(
                      editAndPopulateDirectorsForm?.proof_of_address_comment,
                    );
                  }}
                >
                  Comments{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chat-dots"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div className="col-12">
            <CustomInputField
              type="number"
              maxLength={2}
              ref={register}
              name="periodOnCompanyBoard"
              placeholder="E.g. 4 "
              label={`Period on the company’s board (years)`}
              errors={errors.periodOnCompanyBoard}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-12">
            <CustomTextArea
              maxLength={256}
              ref={register}
              placeholder="E.g. Former MD of Fidelity Bank - Jan, 2002 - June, 2022"
              label="Work Experience"
              name="workExperience"
              errors={errors.workExperience}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12 col-lg-12 pt-2">
            <CustomTextArea
              maxLength={256}
              ref={register}
              placeholder="E.g. Special Adviser on finance, Fidelity Bank. July 2014 - June, 2021"
              label="Experience relevant to the role/business"
              name="experienceTotheRole"
              errors={errors.experienceTotheRole}
              showRequiredIcon={true}
            />
          </div>

          <div className=" w-50">
            <button
              id="buttonAddMore"
              type="submit"
              className="fa fa-plus  advancly-text-btn my-3"
              onClick={handleSubmit(onAddMoreDirectorHandler)}
            >
              <span className="pl-1">
                {" "}
                Add Another Director {addAnotherLoading && <LoaderColored />}
              </span>
            </button>
          </div>
          <div className="col-12">
            <div className=" d-flex justify-content-between flex-wrap mt-2">
              <button
                type="submit"
                onClick={handleSubmit(onSaveContinueHandler)}
                id="saveAndContinue"
                className="advancly-btn my-3"
                disabled={loading}
              >
                Save and Continue
                {loading && <Loader />}
              </button>
              <button
                className="btn advancly-white-btn btn-sm transition-3d-hover mt-2 mb-4"
                type="button"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
      {isDirectorsComplete && isAddMoreDirectors === false && (
        <button onClick={() => {}}>Proceed</button>
      )}
      <CommentsBoardOfDirectorDocsModal fileComments={comments} />
    </div>
  );
};

export default BoardOfDirectorsForm;
