import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import styles from "./NetworkStatus.module.scss";
const NetworkStatus = () => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return (
    <>
      {isOffline && (
        <div className={`${styles.networkStatusContainer}`}>
          <Alert
            message="Your network connection is lost. Please check your internet."
            type="warning"
            closable
            onClose={() => setIsOffline(false)}
          />
        </div>
      )}
    </>
  );
};

export default NetworkStatus;
