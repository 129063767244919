import mixpanel from "mixpanel-browser";

import CONFIG from "../components/config";

interface PeopleProps {
  $name: string;
  $email: string;
  user_id?: number;
  phone: string;
  "customer id"?: string;
  "personal email"?: string;
  "aggregator id"?: string;
  "business name"?: string;
  "business email"?: string;
  "business registration number"?: string;
  "business address"?: string;
  "business category"?: string;
  product?: string;
  "customer type"?: string;
  "date created"?: string;
  "aggregator rep name"?: string;
}

// Initialize Mixpanel
mixpanel.init(CONFIG.MIXPANEL_PROJECT_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

const env_check = process.env.NODE_ENV === "production";

let actions = {
  identify: (unique_id: string | undefined) => {
    if (env_check) mixpanel.identify(unique_id);
  },
  people: {
    set: (props: PeopleProps) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  track: (event_name: string, properties: {}) => {
    if (env_check) mixpanel.track(event_name, properties);
  },
  // track_pageview: (props: any) => {
  //   if (env_check) mixpanel.track_pageview(props);
  // },
};

export let MixPanel = actions;
