import { FormEvent, Fragment, LegacyRef, useEffect, useState } from "react";
import Alert from "../../../components/Alert/Alert.component";
import Loader from "../../../components/Loader/Loader.component";
import { formatMoney } from "../../../helpers/formatter";
import { MixPanel } from "../../../helpers/mixPanel";

const MakeRepaymentModal = ({
  modalActionType,
  state,
  aggregatorDetails,
  submitRepayment,
  finalSubmit,
  dismissRef,
}: {
  modalActionType: string;
  state: {
    dataToStore: any;
    totalAmount: any;
    repaymentError: boolean;
    repaymentSuccess: boolean;
    repaymentLoading: boolean;
    reference: string;
    deficitBalance: number;
  };
  aggregatorDetails: any;
  submitRepayment: (e: FormEvent) => void;
  finalSubmit: () => void;
  dismissRef: LegacyRef<HTMLButtonElement> | undefined;
}) => {
  const [copied, setCopied] = useState("");
  const copyText = (text: string) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => setCopied(""), 3000);
  };

  useEffect(() => {
    MixPanel.track(`Page View: MakeRepaymentModal.tsx`, {
      page: "MakeRepaymentModal.tsx - (Spark)",
    });
  }, []);

  return (
    <div
      className="modal fade"
      id="MakeRepaymentModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="MakeRepaymentModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {(() => {
            switch (modalActionType) {
              case "step1":
                return (
                  <Fragment>
                    <div className="modal-header border-bottom-0">
                      <h6 className=" font-weight-bold w-100">
                        Total Repayment Amount
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={dismissRef}
                      ></button>
                    </div>
                    <hr
                      style={{
                        width: "80%",
                        marginTop: 0,
                      }}
                    ></hr>
                    <div className="modal-body">
                      <div className="mb-4">
                        <span className="form-label">
                          Repayment Amount :{" "}
                          <span className="font-weight-bold">
                            {formatMoney(state?.totalAmount.toFixed(2))}
                          </span>
                        </span>
                      </div>
                      <div className="mb-4">
                        <span className="form-label">
                          Charge:{" "}
                          <span className="font-weight-bold">
                            {formatMoney(
                              aggregatorDetails?.transfer_charge.toFixed(2),
                            )}
                          </span>
                        </span>
                      </div>
                      <div className="mb-4">
                        <span className="form-label">
                          Total:{" "}
                          <span className="font-weight-bold">
                            {formatMoney(
                              (
                                state?.totalAmount +
                                aggregatorDetails?.transfer_charge
                              ).toFixed(2),
                            )}
                          </span>
                        </span>
                      </div>
                      {state?.dataToStore?.length ? (
                        <div className="mb-5">
                          <span className="form-label">
                            Repayment for{" "}
                            <span className="font-weight-bold">
                              {state?.dataToStore?.length}
                            </span>{" "}
                            {state?.dataToStore?.length > 1 ? "loans" : "loan"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                        <button
                          type="button"
                          className="btn btn-sm advancly-nobg-btn mr-3"
                          //   onClick={(e) => resetDataToStore(e)}
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissRef}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm advancly-btn"
                          onClick={(e) => {
                            submitRepayment(e);
                          }}
                        >
                          Repay Loan(s){state?.repaymentLoading && <Loader />}
                        </button>
                      </div>
                    </div>
                  </Fragment>
                );
              case "step2":
                return (
                  <Fragment>
                    <div className="modal-header border-bottom-0">
                      <h6 className=" font-weight-bold w-100">
                        Repayment Via Bank Transfer
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={dismissRef}
                      ></button>
                    </div>
                    <hr
                      style={{
                        width: "80%",
                        marginTop: 0,
                      }}
                    ></hr>
                    <div className="modal-body">
                      {copied && <Alert message={copied} type="success" />}
                      <p className="form-label">
                        Insufficient fund in your wallet
                      </p>
                      <div className="mb-4">
                        <span className="form-label">Repayment Amount: </span>
                        <span className="page-subtitle">
                          {" "}
                          {formatMoney(state?.totalAmount)}
                        </span>
                      </div>
                      <div className="mb-4">
                        <span className="form-label">Deficit Balance: </span>
                        <span className="page-subtitle">
                          {formatMoney(state?.deficitBalance)}
                        </span>
                      </div>
                      <p className="form-label mb-4">
                        1. Copy the account details below
                      </p>
                      <div className="bg-lighter-grey p-3">
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Account Name</small>
                          </div>
                          <h6 className="page-subtitle">
                            {aggregatorDetails?.biz_name}
                          </h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Account Number</small>
                          </div>
                          <h6 className="page-subtitle">
                            {aggregatorDetails?.account_number}
                            <span
                              className="font-weight-b cursor-pointer ml-4"
                              onClick={() =>
                                copyText(aggregatorDetails?.account_number)
                              }
                            >
                              <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                            </span>
                          </h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-4">
                            <small>Bank Name</small>
                          </div>
                          <h6 className="page-subtitle">
                            {aggregatorDetails?.bank_name}
                          </h6>
                        </div>
                      </div>
                      <h6 className="form-label my-4 text-center text-primary">
                        <span
                          className="font-weight-b cursor-pointer ml-4"
                          onClick={() =>
                            copyText(
                              `${aggregatorDetails?.biz_name},
                              ${aggregatorDetails?.account_number},
                             ${aggregatorDetails?.bank_name}`,
                            )
                          }
                        >
                          <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                          Copy Account Details
                        </span>
                      </h6>
                      <p className="form-label mb-4">
                        2. Transfer the selected amount to the account
                        information displayed above.
                      </p>
                      <p className="form-label">
                        3. Your loan will be repaid once payment is confirmed.
                      </p>
                      <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                        <button
                          type="button"
                          className="btn btn-sm advancly-nobg-btn mr-3"
                          //   onClick={(e) => resetDataToStore(e)}
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissRef}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm advancly-btn"
                          onClick={(e: any) => {
                            finalSubmit();
                          }}
                        >
                          I have Paid {state.repaymentLoading && <Loader />}
                        </button>
                      </div>
                    </div>
                  </Fragment>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};
export default MakeRepaymentModal;
