import React, { useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import moment from "moment";
import { useHistory } from "react-router-dom";
import useBorrowerDetails from "../../custom-hooks/useBorrowerDetails";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";
import { ReactComponent as SearchIconGrey } from "../../svg/icons/search-icon-grey.svg";
import {MixPanel} from "../../helpers/mixPanel";

interface Iborrower {
  first_name: string;
  last_name: string;
  gender: string;
  borrower_type_value: string;
  my_biz_name: string;
  borrower_phone: string;
  pub_date: string;
  customer_category: string;
  borrower_id: number;
  email: string;
}
export default function BorrowersMain() {
  const [searchString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");

  const { data, status } = useBorrowerDetails({ searchString });

  let history = useHistory();

  const handleClick = (borrowerId: number) => {
    history.push(`/borrowers/${borrowerId}`);
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "BorrowersMain.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: Borrowers.tsx`, {
      page: "Borrowers.tsx - (Spark)",
    });
  }, []);

  if (status === "loading") return <PageLoader />;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="page-title mb-4">Borrowers</h3>
        </div>
      </div>
      <div className="advancly-wrapper-container">
        <div className="">
          <div className="d-flex justify-content-between align-items-center advancly-sub-wrapper">
            <div className="d-flex align-items-center mb-2">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text ">
                    <span className="">
                      <SearchIconGrey />
                    </span>
                  </span>
                </div>
                <input
                  type="text"
                  className="mr-0 search-query form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ borderLeft: "none" }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="pr-2 pt-1">Showing:</p>
              <CustomSelectDropdown
                onChange={(e: any) => setSize(e.target?.value)}
                value={size}
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          <div className="advancly-sub-wrapper  mt-3">
            <div className="table-responsive">
              <table className="table-style">
                <thead className="">
                  <tr>
                    <th className="th-td-style th-style ">Name</th>
                    <th className="th-td-style th-style ">Sex</th>
                    <th className="th-td-style th-style ">Borrower Type</th>
                    <th className="th-td-style th-style ">Category Type</th>
                    <th className="th-td-style th-style ">Business Name</th>
                    <th className="th-td-style th-style ">Email</th>
                    <th className="th-td-style th-style ">Phone Number</th>
                    <th className="th-td-style th-style ">Onboarding Date</th>
                    <th className="th-td-style th-style ">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length ? (
                    data
                      .filter(
                        (borrower: Iborrower) =>
                          (borrower.first_name &&
                            borrower.first_name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())) ||
                          (borrower.last_name &&
                            borrower.last_name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())) ||
                          (borrower.gender &&
                            borrower.gender
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())) ||
                          (borrower.email &&
                            borrower.email
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())) ||
                          (borrower.borrower_phone &&
                            borrower.borrower_phone
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())) ||
                          (borrower.pub_date &&
                            borrower.pub_date
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()))
                      )
                      .slice(
                        (pageNumber - 1) * size,
                        size * (pageNumber - 1) + size
                      )
                      .map((data: Iborrower, id: number) => {
                        const {
                          first_name,
                          last_name,
                          gender,
                          borrower_type_value,
                          my_biz_name,
                          borrower_phone,
                          pub_date,
                          customer_category,
                          borrower_id,
                          email,
                        } = data;
                        return (
                          <tr key={id}>
                            <td className="text-capitalize th-td-style">
                              {first_name && first_name.toLowerCase()}{" "}
                              {last_name && last_name.toLowerCase()}
                            </td>
                            <td className="text-capitalize th-td-style">
                              {gender ? gender : "N/A"}
                            </td>
                            <td className="th-td-style ">
                              {borrower_type_value
                                ? borrower_type_value
                                : "N/A"}
                            </td>
                            <td className="th-td-style ">
                              {customer_category ? customer_category : "N/A"}
                            </td>

                            <td className="th-td-style ">
                              {my_biz_name ? my_biz_name : "N/A"}
                            </td>
                            <td className="th-td-style ">
                              {email ? email : "N/A"}
                            </td>
                            <td className="th-td-style ">
                              {borrower_phone ? borrower_phone : "N/A"}
                            </td>
                            <td className="th-td-style ">
                              {moment(pub_date).format("DD-MM-YYYY")}
                            </td>
                            <td className="dropright  th-td-style ">
                              <span
                                className=""
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <MoreIconHorizontal />
                              </span>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                onClick={() => handleClick(borrower_id)}
                              >
                                <button className="btn btn-sm advancly-nobg-btn">
                                  View Profile
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <NoTableDataAvailable />
                  )}
                </tbody>
              </table>
            </div>
            <Paginator
              size={size}
              page={pageNumber}
              count={data ? data.length : 0}
              changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
