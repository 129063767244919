import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { errorHandler } from "../../../../helpers/errorHandler";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import Loader from "../../../../components/Loader/Loader.component";
import { useQueryCache } from "react-query";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import { useForm } from "react-hook-form";
import LoaderColored from "../../../../components/Loader/LoaderColored";
import styles from "./GetStartedUploadDocumentsNew.module.scss";
import useListOfDocumentsToUpload from "../../../../custom-hooks/useListOfDocumentsToUpload";
import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import ListOfUploadedDocs from "./ListOfUploadedDocs";
import useListOfDocumentsAlreadyUploaded from "../../../../custom-hooks/useListOfDocumentsAlreadyUploaded";
import { Link, useHistory } from "react-router-dom";
import { putDataWithDotNet } from "../../../../apis/dotNetApiMethods";
import advanclyLogo from "../../../../img/advancly_logo.svg";
import OnboardingBackWordButton from "../../../../components/OnboardingBackWordButton/OnboardingBackWordButton";
import { MixPanel } from "../../../../helpers/mixPanel";
import { decrypt } from "../../../../helpers/encryptor";

export enum DocumentUploadApprovalStatus {
  pending = 1,
  approved = 2,
  declined = 3,
}
const GetStartedUploadDocumentsNew = () => {
  const [loading, setLoading] = useState(false);
  const [loadingProceed, setLoadingProceed] = useState(false);
  const [currentListOfAcceptedFiles, setCurrentListOfAcceptedFiles] =
    useState<any>();
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [aggregatorDocumentId, setAggregatorDocumentId] = useState<
    number | null
  >(null);
  const [currentFieldDocumentId, setCurrentFieldDocumentId] =
    useState<any>(null);
  const [isProceedButtonDisabled, setIsProceedButtonDisabled] = useState(true);
  const history = useHistory();
  const queryCache = useQueryCache();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: true,
  });
  const { register, handleSubmit, errors, reset, setValue } = useForm({});
  const scrollRef = useRef<HTMLDivElement | any>(null);
  const email = sessionStorage.getItem("email");
  const decryptedEmail = JSON.parse(decrypt(email));

  useEffect(() => {
    MixPanel.track(`Page View: GetStartedUploadNewDocuments.tsx`, {
      page: "GetStartedUploadNewDocuments.tsx - (Spark)",
    });
  }, []);

  const {
    data: listOfDocumentsToUpload,
    status: listOfDocumentsToUploadStatus,
    error: listOfDocumentsToUploadError,
  } = useListOfDocumentsToUpload();

  const {
    data: uploadedDocs,
    status: uploadedDocsStatus,
    error: uploadedDocsError,
  } = useListOfDocumentsAlreadyUploaded();
  //check if documents was uploaded for all required categories
  const filterAllRequiredDocuments = uploadedDocs?.filter(
    ({ is_mandatory }: { is_mandatory: boolean }) => {
      return is_mandatory === true;
    },
  );
  const filterDocumentsToUpload = listOfDocumentsToUpload?.filter(
    ({ is_mandatory }: { is_mandatory: boolean }) => {
      return is_mandatory === true;
    },
  );

  useEffect(() => {
    if (
      filterAllRequiredDocuments?.length === filterDocumentsToUpload?.length
    ) {
      setIsProceedButtonDisabled(false);
    } else {
      setIsProceedButtonDisabled(true);
    }
  }, [filterAllRequiredDocuments, filterDocumentsToUpload]);

  useEffect(() => {
    if (currentFieldDocumentId) {
      const uploadedDocId = listOfDocumentsToUpload?.find(
        ({ id }: { id: number }) => {
          return Number(id) === Number(currentFieldDocumentId);
        },
      );
      setTimeout(() => {
        setValue("AllDocumentsCategories", uploadedDocId?.id);
      }, 2000);
    }
    //eslint-disable-next-line
  }, [currentFieldDocumentId, setValue]);
  useEffect(() => {
    setCurrentListOfAcceptedFiles(acceptedFiles);
  }, [acceptedFiles]);

  const files = currentListOfAcceptedFiles ? (
    currentListOfAcceptedFiles?.map((file: any, index: number) => {
      return (
        <li key={index} className={styles.selectedFilesList}>
          {file.path} -{(parseFloat(file.size) / 1024).toFixed(2)}kb
        </li>
      );
    })
  ) : (
    <div>""</div>
  );
  const handlescroll = (message: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setError(message);
    setTimeout(() => {
      setError("");
    }, 3000);
  };
  const handleScrollDown = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const onSubmitHandler = async (data: any) => {
    if (!data?.AllDocumentsCategories) {
      return handlescroll("Please select document category to upload");
    }
    if (acceptedFiles?.length === 0) {
      return handlescroll("Please select a file");
    }
    // check  file size for each file
    const acceptedFileSize = acceptedFiles.every(({ size }) => {
      return size > 25 * 1024 * 1024;
    });
    if (acceptedFileSize) {
      return handlescroll(
        "File size exceeds the maximum allowed limit of 25MB. Please select a smaller file.",
      );
    }
    const formData = new FormData();

    // Append data from aggregator_document to FormData
    const categorieId = Number(data?.AllDocumentsCategories);
    formData.append(
      "AggregatorDocuments[0].DocumentFieldId",
      //@ts-ignore
      categorieId,
    );
    formData.append("AggregatorDocuments[0].DocumentTypeCode", "");
    formData.append("AggregatorDocuments[0].Type", "");

    // Append files to FormData
    acceptedFiles.forEach((file, index) => {
      formData.append(`AggregatorDocuments[0].Files`, file);
    });
    //@ts-ignore

    try {
      setLoading(true);
      setError(null);

      const res = await postData(
        apiEndpoints.getListOfDocumentsAlreadyUploaded,
        formData,
        true,
      );
      setLoading(false);
      setSuccess(res?.message);
      queryCache.invalidateQueries("getListOfDocumentsAlreadyUploaded");
      setCurrentListOfAcceptedFiles(null);
      reset();
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "GetStartedUploadDocumentsNew.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));

      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const FinalSubmission = async (e: any) => {
    e.preventDefault();
    setError(null);
    setLoadingProceed(true);
    try {
      const res = await putDataWithDotNet(
        apiEndpoints.getListOfDocumentsAlreadyUploadedSingle,
        {},
        true,
      );

      if (res.status_code === 200) {
        MixPanel.track("Onboard", {
          distinct_id: `${decryptedEmail}`,
          product: "Spark",
          status: "Documents uploaded Successfully",
          step: "GetStartedUploadNewDocuments.tsx",
        });
        setLoadingProceed(false);
        history.push({
          pathname: `/get-started/onboarding`,
          state: {
            successMessage: "Well done! Your uploads have been saved",
          },
        });
        setTimeout(() => {}, 5000);
      } else {
        setError(res);

        MixPanel.track("Onboard", {
          distinct_id: `${decryptedEmail}`,
          product: "Spark",
          status: "Failed: Documents upload failed",
          step: "GetStartedUploadNewDocuments.tsx",
        });
      }
    } catch (error: any) {
      MixPanel.track("Onboard", {
        distinct_id: `${decryptedEmail}`,
        product: "Spark",
        status: `Failed: ${error?.response?.data?.message}`,
        step: "GetStartedUploadNewDocuments.tsx",
      });
      setLoadingProceed(false);
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "GetStartedUploadDocumentsNew.tsx",
        },
      });
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
      }
    }
    setLoadingProceed(false);
  };

  return (
    <>
      <div className={styles.advanclylogoContainer}>
        <Link to="/">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
        <OnboardingBackWordButton />
      </div>
      <div
        className={`${styles.center_container} d-flex justify-content-center align-items-center flex-column `}
      >
        <div className={`${styles.headingContainer}`}>
          <h2 className={`${styles.documentnHeading}`}>
            Upload Documents For Spark
          </h2>
          <p className={`${styles.InfoParagraph}`}>
            To ensure compliance with relevant regulatory laws and facilitate
            Due Diligence, please upload all the required documents.
          </p>
          <p className={`${styles.InfoParagraph}`}>
            Below are a list documents uploaded by you
          </p>
        </div>
        {
          <div style={{ width: "455px", textAlign: "center" }}>
            {success && <p className="alert alert-success small">{success}</p>}
            {error && <p className="alert alert-danger small">{error}</p>}
            {uploadedDocsError && (
              <p className="alert alert-danger small">
                {uploadedDocsError as any}
              </p>
            )}
            {listOfDocumentsToUploadError && (
              <p className="alert alert-danger small">
                {listOfDocumentsToUploadError as any}
              </p>
            )}
          </div>
        }
        {/* <p className="alert alert-danger small">
          </p> */}
        {/* start of list of uploaded  documents */}
        <div style={{ maxWidth: "455px" }}>
          {uploadedDocsStatus === "loading" ? (
            <div className="my-4">
              <PageLoader />
            </div>
          ) : (
            uploadedDocs
              ?.sort((a: any, b: any) => a.position - b.position)
              ?.map((document: any) => {
                const {
                  aggregator_documents,
                  document_field_Name,
                  is_mandatory,
                  document_field_id,
                } = document;
                return (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    key={document_field_id}
                  >
                    <ListOfUploadedDocs
                      aggregatorDocuments={aggregator_documents}
                      documentFieldName={document_field_Name}
                      isMandatory={is_mandatory}
                      setAggregatorDocumentId={setAggregatorDocumentId}
                      aggregatorDocumentId={aggregatorDocumentId}
                      setCurrentFieldDocumentId={setCurrentFieldDocumentId}
                      currentFieldDocumentId={currentFieldDocumentId}
                      handleScrollDown={handleScrollDown}
                    />
                  </div>
                );
              })
          )}
          {/* list of uploaded documents ends here */}
          <div className="col-12">
            <hr className={styles.dottedlines} />
          </div>
        </div>
        <div className="" style={{ width: "455px" }}>
          {
            <>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="">
                  <small className="text-danger">
                    {" "}
                    Upload documents for categories marked as required
                  </small>
                  <CustomSelectDropdown
                    name="AllDocumentsCategories"
                    ref={register}
                    label="Documents Categories"
                    errors={errors.AllDocumentsCategories}
                  >
                    <option value={""}>
                      {" "}
                      {listOfDocumentsToUploadStatus === "loading"
                        ? "Loading.... "
                        : "Select Document Category"}
                    </option>
                    {listOfDocumentsToUpload
                      ?.sort((a: any, b: any) => a.position - b.position)
                      ?.map((documentList: any) => {
                        const { title, id, is_mandatory } = documentList;
                        return (
                          <option key={id} value={id}>
                            {title} ---{" "}
                            {is_mandatory ? " required" : "optional"}
                          </option>
                        );
                      })}
                  </CustomSelectDropdown>
                </div>

                <div className="">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div
                      className={styles.financialDocumentUploadContainer}
                      ref={scrollRef}
                    >
                      <input {...getInputProps()} />
                      <h5>Upload File</h5>
                      <p className={styles.dropParagraph}>
                        Drag & drop your file here or
                        <span
                          className="color-blue"
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          click to select files
                        </span>{" "}
                      </p>
                      {currentListOfAcceptedFiles?.length > 0 && (
                        <small className="text-muted text-underline cursor-pointer transition-3d-hover mt-4">
                          Click to remove or change selected files
                        </small>
                      )}
                    </div>
                  </div>
                  {files.length > 0 && (
                    <aside
                      className="text-muted mt-2 "
                      style={{ cursor: "not-allowed" }}
                    >
                      <h5> Selected file</h5>
                      <small className="text-danger">
                        Note: Maximum size allowed per file is 25MB{" "}
                      </small>
                      <ul>{files}</ul>
                    </aside>
                  )}
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="btn advancly-btn w-100 my-2"
                    disabled={loading}
                  >
                    {" "}
                    {"Upload & Add Another"}
                    {loading && <Loader />}
                  </button>
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btn advancly-white-btn w-100 my-2"
                    disabled={loading || isProceedButtonDisabled}
                    onClick={FinalSubmission}
                  >
                    {" "}
                    Proceed {loadingProceed && <LoaderColored />}
                  </button>
                </div>
              </form>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default GetStartedUploadDocumentsNew;
