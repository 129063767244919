import React, { useEffect, useState } from "react";
import styles from "../../GetStarted.module.scss";

// import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import { postDataWithDotNet } from "../../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { useHistory } from "react-router";
import Loader from "../../../../components/Loader/Loader.component";
import EligibilityTestField from "./EligibilityTestField";
import PageLoaderAnimation from "../../../../components/PageLoader/PageLoaderAnimation";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { useForm } from "react-hook-form";
import { MixPanel } from "../../../../helpers/mixPanel";
import { decrypt } from "../../../../helpers/encryptor";

export default function EligibilityTestStepTwo({
  setCurrentStep,
  status,
  onOptionAnswerChanged,
  onMultipleAnswerChanged,
  onTextAnswerChanged,
  questions,
  answers,
  sparkEligbilityAnswers,
  setSparkEligbilityAnswers,
  setGeneralEligbilityAnswers,
  questionsAnswered,
}: any) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { register, handleSubmit, setValue } = useForm();

  const history = useHistory();
  const email = sessionStorage.getItem("email");
  const decryptedEmail = JSON.parse(decrypt(email));

  useEffect(() => {
    appInsights.trackPageView({
      name: "EligibilityTestStepTwo.jsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: EligibilityTestStepTwo.tsx`, {
      page: "EligibilityTestStepTwo.tsx - (Spark)",
    });
  }, []);

  const IsEmbedForInvestments = questions?.find((question: any) => {
    return (
      !question?.isGeneral &&
      question?.productTypes.length > 0 &&
      question?.productTypes.length !== 0 &&
      question?.productTypes.some(({ product }: { product: string }) => {
        return product === "EmbeddedInvestment";
      })
    );
  });
  const IsEmbedForLoans = questions?.find((question: any) => {
    return (
      !question?.isGeneral &&
      question?.productTypes.length > 0 &&
      question?.productTypes.length !== 0 &&
      question?.productTypes.some(({ product }: { product: string }) => {
        return product === "EmbeddedLoans";
      })
    );
  });
  useEffect(() => {
    // this effect is only use to store state of user information
    //This was done so when the user is to go back after an error the filled value would be there
    if (sparkEligbilityAnswers) {
      let dataObjects = Object.entries(sparkEligbilityAnswers);
      for (const [key, value] of dataObjects) {
        setValue(key, value);
      }
    }
  }, [sparkEligbilityAnswers, setValue]);
  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    //Ensure that all ids are present
    try {
      if (IsEmbedForInvestments || IsEmbedForLoans) {
        setSparkEligbilityAnswers(data);
        setCurrentStep(3);
      } else {
        await postDataWithDotNet(
          apiEndpoints.addAggregatorEligibilityTest,
          answers,
          true,
        );
        setSparkEligbilityAnswers();
        setGeneralEligbilityAnswers();
        setLoading(false);

        MixPanel.track("Onboard", {
          distinct_id: `${decryptedEmail}`,
          product: "Spark",
          status: "Eligibility Response saved",
          step: "EligibilityTestStepTwo.tsx",
        });

        history.push({
          pathname: `/get-started/onboarding`,
          state: {
            successMessage: "Well done! Your Responses have been saved",
          },
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "EligibilityTestStepTwo.jsx",
        },
      });
      MixPanel.track("Onboard", {
        distinct_id: `${decryptedEmail}`,
        product: "Spark",
        status: "Failed: Eligibility Response failed to save",
        step: "EligibilityTestStepTwo.tsx",
      });
      setLoading(false);
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setSparkEligbilityAnswers(data);
        setError(errorHandler(error));
      }
    }
  };

  if (status === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoaderAnimation />
      </div>
    );
  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {/* {success && <p className=" alert alert-success small">{success}</p>} */}
      <div className={` mt-3`} style={{ width: "60%" }}>
        {" "}
        <small
          className="color-mid-blue  cursor-pointer font-weight-bold  "
          onClick={() => setCurrentStep(1)}
        >
          &#8592; Go back
        </small>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 mb-4  mt-5 flex-wrap">
        <h3 className="page-title mb-0">Spark Eligibility Test</h3>
        <div className={styles.step}>Step 2 of 3</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {questions && questions.length ? (
            questions.map((obj: any, _id: any) => {
              const {
                conditionalAnswerId,
                conditionalQuestionId,
                id,
                isConditional,
                isMandatory,
                question,
                questionAnswerType,
                questionAnswers,
                isGeneral,
                productTypes,
              } = obj;
              const IsSparkLoansForCustomers =
                productTypes?.length > 0 &&
                productTypes?.some(({ product }: { product: string }) => {
                  return product === "SparkLoanForCustomers";
                });
              const IsSparkLoansForBusiness =
                productTypes?.length > 0 &&
                productTypes?.some(({ product }: { product: string }) => {
                  return product === "SparkLoanForBusiness";
                });

              return (
                <div className="col-xs-12  col-md-12  pl-0 pr-0 " key={id}>
                  {/* for spark Loan for customers */}
                  {(IsSparkLoansForCustomers || IsSparkLoansForBusiness) &&
                  !isGeneral &&
                  !isConditional ? (
                    //@ts-ignore
                    <EligibilityTestField
                      id={id}
                      isMandatory={isMandatory}
                      question={question}
                      questionAnswerType={questionAnswerType}
                      questionAnswers={questionAnswers}
                      onOptionAnswerChanged={onOptionAnswerChanged}
                      onMultipleAnswerChanged={onMultipleAnswerChanged}
                      onTextAnswerChanged={onTextAnswerChanged}
                      reference={register}
                      questionsAnswered={questionsAnswered}
                    />
                  ) : (
                    ""
                  )}

                  {(IsSparkLoansForCustomers || IsSparkLoansForBusiness) &&
                  !isGeneral &&
                  isConditional &&
                  answers &&
                  answers.length > 0
                    ? // eslint-disable-next-line array-callback-return
                      answers
                        .filter(
                          (answer: any, _id: number) =>
                            Number(answer.questionId) ===
                              conditionalQuestionId &&
                            Number(
                              answer.questionAnswerId.includes(
                                conditionalAnswerId,
                              ),
                            ),
                        )
                        .map((answer: any) => {
                          return (
                            <div key={_id}>
                              {
                                //@ts-ignore
                                <EligibilityTestField
                                  id={id}
                                  isMandatory={isMandatory}
                                  question={question}
                                  questionAnswerType={questionAnswerType}
                                  questionAnswers={questionAnswers}
                                  onOptionAnswerChanged={onOptionAnswerChanged}
                                  onMultipleAnswerChanged={
                                    onMultipleAnswerChanged
                                  }
                                  onTextAnswerChanged={onTextAnswerChanged}
                                  reference={register}
                                  questionsAnswered={questionsAnswered}
                                />
                              }
                            </div>
                          );
                        })
                    : ""}
                </div>
              );
            })
          ) : (
            <div className="w-100 p-5">
              <h3 className="text-center">No Spark test questions available</h3>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end flex-wrap flex-wrap mt-3 mb-4">
            <button
              className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
              type="button"
              onClick={() => setCurrentStep(1)}
            >
              Previous
            </button>
            <button
              className="btn advancly-btn btn-sm transition-3d-hover mt-2"
              type="submit"
              disabled={loading}
            >
              {IsEmbedForInvestments || IsEmbedForLoans ? "Next" : "Save"}
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
