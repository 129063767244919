import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ReactComponent as InfoIcon } from "../../../../../img/info_icon.svg";
import { useForm } from "react-hook-form";
import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../../../components/CustomHTMLElements/CustomSelectDropdown";
import Loader from "../../../../../components/Loader/Loader.component";
import styles from "../../../GetStarted.module.scss";
import { yupValidators } from "../../../../../helpers/yupValidators";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { postData, putData } from "../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { handleFormatAmountNoCurrency } from "../../../../../helpers/formatterNoDecimal";
import { useQueryCache } from "react-query";

import { useAuth } from "../../../../../Context/auth.context";
import useGeneralCurrencies from "../../../../../custom-hooks/useGeneralCurrencies";
import { MixPanel } from "../../../../../helpers/mixPanel";
import { decrypt } from "../../../../../helpers/encryptor";
type TshareholderParams = {
  parentShareholderLoading: boolean;
  setParentShareholderLoading: Dispatch<SetStateAction<boolean>>;
  setParentShareholderError: Dispatch<SetStateAction<string | null>>;
  setParentShareholderSuccess: Dispatch<SetStateAction<string | null>>;
  setIsAddParentShareholder: Dispatch<SetStateAction<boolean>>;
  setEditShareholderTable: Dispatch<SetStateAction<boolean>>;
  shareholdersListData: any;
  fullShareholderData: any;
  isAddParentShareholder: boolean;
  editShareholderTable: boolean;
  scrollToTop?: (() => void) | any;
};

const schema = yup.object({
  shareholderType: yupValidators.shareholderType,
  shareholderName: yupValidators.shareholderName,
  numberOfShares: yupValidators.numberOfShares,
  shareholderCurrency: yupValidators.shareholderCurrency,
  valueofsharesamount: yupValidators.valueofsharesamount,
  percentOfShares: yupValidators.percentOfShares,
});
const ShareholderParentForm = ({
  parentShareholderLoading,
  setParentShareholderLoading,
  setParentShareholderError,
  setParentShareholderSuccess,
  setIsAddParentShareholder,
  shareholdersListData,
  isAddParentShareholder,
  editShareholderTable,
  setEditShareholderTable,
  fullShareholderData,
  scrollToTop,
}: TshareholderParams) => {
  const [currentShareholderType, setCurrentShareholderType] = useState("");
  const [sharesAmountValue, setSharesAmountValue] = useState("");
  const [numberOfSharesValue, setNumberOfSharesValue] = useState("");
  const queryCache = useQueryCache();
  const { aggregatorDetails } = useAuth();

  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const email = sessionStorage.getItem("email");
  const decryptedEmail = JSON.parse(decrypt(email));

  const {
    data: generalCurrencyData,
    isLoading: generalCurrencyLoading,
    error: generalCurrencyError,
  } = useGeneralCurrencies();
  const resetSharesAmountAndValue = () => {
    setSharesAmountValue("");
    setNumberOfSharesValue("");
  };
  useEffect(() => {
    if (
      fullShareholderData?.parent_shareholder_id === null &&
      editShareholderTable
    ) {
      setTimeout(() => {
        if (fullShareholderData?.shareholder_type === "Individual") {
          setValue("shareholderType", "1");
        } else {
          setValue("shareholderType", "2");
        }
      }, 3000);
      setValue("shareholderName", fullShareholderData?.firstname);
      setValue("shareholderName", fullShareholderData?.firstname);

      setTimeout(() => {
        setValue("shareholderCurrency", fullShareholderData?.currency);
      }, 3000);
      setValue("percentOfShares", fullShareholderData?.percentage_shareholding);
      setTimeout(() => {
        setValue("numberOfShares", fullShareholderData?.number_of_shares);
      }, 3000);
      setTimeout(() => {
        setValue("valueofsharesamount", fullShareholderData?.amount);
      }, 3000);

      setTimeout(() => {
        setValue(
          "parentshareholder",
          fullShareholderData?.parent_shareholder_id
        );
      }, 6000);
    }
  }, [editShareholderTable, fullShareholderData, setValue]);

  const parentOnsubmitHandler = async (parentShareholderdata: any) => {
    const {
      shareholderType,
      shareholderName,
      shareholderLastName,
      numberOfShares,
      shareholderCurrency,
      valueofsharesamount,
      percentOfShares,
    } = parentShareholderdata;

    const reqBody = {
      firstname: shareholderName,
      lastname: shareholderType === "1" ? shareholderLastName : "",
      number_of_shares: +handleFormatAmountNoCurrency(String(numberOfShares)),
      currency: shareholderCurrency,
      amount: +handleFormatAmountNoCurrency(String(valueofsharesamount)),
      percentage_shareholding: Number(percentOfShares),
      type: Number(shareholderType),
      shareholder_id: null,
    };

    try {
      setParentShareholderLoading(true);
      setParentShareholderError(null);
      let response;
      if (editShareholderTable) {
        response = await putData(
          apiEndpoints.SHAREHOLDER_ONBOARDING,
          [{ ...reqBody, id: fullShareholderData?.id }],
          true
        );
      } else {
        response = await postData(
          apiEndpoints.SHAREHOLDER_ONBOARDING,
          [reqBody],
          true
        );
      }
      setParentShareholderLoading(false);
      setParentShareholderSuccess(response?.message);
      scrollToTop();
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.customer_id, Type: null },
        "getShareholders",
      ]);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.customer_id, Type: 2 },
        "getShareholders",
      ]);
      setIsAddParentShareholder(false);
      setTimeout(() => {
        setParentShareholderSuccess(null);
        setEditShareholderTable(false);
        reset();
        resetSharesAmountAndValue();
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "ShareholderParentForm.tsx" },
      });

      MixPanel.track("Onboard", {
        distinct_id: `${decryptedEmail}`,
        product: "Spark",
        status: `Failed: ${errorHandler(error)}`,
        step: "GetStartedBusinessBasicInfo.tsx",
      });
      setParentShareholderLoading(false);
      setParentShareholderError(errorHandler(error));
      scrollToTop();

      setEditShareholderTable(false);
    }
  };
  return (
    <div className="py-4" style={{ maxWidth: "490px" }}>
      <h5>Shareholder</h5>
      <form onSubmit={handleSubmit(parentOnsubmitHandler)}>
        <div className="row py-2">
          <div className="col-12">
            <CustomSelectDropdown
              ref={register}
              name="shareholderType"
              label="Shareholder Type"
              errors={errors.shareholderType}
              showRequiredIcon={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCurrentShareholderType(e.target.value)
              }
            >
              <option value="">Select shareholder type</option>
              <option value="1">Individual</option>

              <option value="2">Corporate</option>
            </CustomSelectDropdown>
          </div>
          {currentShareholderType === "2" ? (
            <div className="col-12">
              <CustomInputField
                type="text"
                maxLength={128}
                ref={register}
                name="shareholderName"
                placeholder="Enter shareholder's Name"
                label="Shareholder's Name"
                errors={errors.shareholderName}
                showRequiredIcon={true}
              />
            </div>
          ) : (
            <>
              <div className="col-12">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  ref={register}
                  name="shareholderName"
                  placeholder="Enter shareholder's First Name"
                  label="Shareholder's First Name"
                  errors={errors.shareholderName}
                  showRequiredIcon={true}
                />
              </div>
              <div className="col-12">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  ref={register}
                  name="shareholderLastName"
                  placeholder="Enter shareholder's Last Name"
                  label="Shareholder's Last Name"
                  errors={errors.shareholderName}
                  showRequiredIcon={true}
                />
              </div>
            </>
          )}
          <div className="col-12">
            <CustomInputField
              type="number"
              enableSeparator
              ref={register}
              value={numberOfSharesValue}
              name="numberOfShares"
              placeholder="Enter number of shares "
              label="number of shares"
              errors={errors.numberOfShares}
              showRequiredIcon={true}
              onChange={(values: any) => {
                const { formattedValue } = values;
                setNumberOfSharesValue(formattedValue);
              }}
            />
          </div>
          <div className="col-12">
            <CustomSelectDropdown
              ref={register}
              name="shareholderCurrency"
              label="Currency"
              errors={errors.shareholderCurrency}
              showRequiredIcon={true}
              extraLabel={generalCurrencyLoading ? "Loading...." : ""}
            >
              <option value="">Select currency</option>
              {generalCurrencyData &&
                generalCurrencyData?.map((currency: any) => {
                  const { currency_name, currency_code } = currency;
                  return (
                    <option key={currency_name} value={currency_code}>
                      {currency_name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
            <small className="text-danger">{generalCurrencyError} </small>
          </div>
          <div className="col-12">
            <CustomInputField
              type="number"
              ref={register}
              value={sharesAmountValue}
              name="valueofsharesamount"
              placeholder="Enter value of shares in amount "
              label="Value(Amount)"
              errors={errors.valueofsharesamount}
              showRequiredIcon={true}
              enableSeparator
              onChange={(values: any) => {
                const { formattedValue } = values;
                setSharesAmountValue(formattedValue);
              }}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="number"
              maxLength={5}
              ref={register}
              name="percentOfShares"
              placeholder="Enter share percentage "
              label="Percentage of shareholding"
              errors={errors.percentOfShares}
              extraLabel={"%"}
              showRequiredIcon={true}
              step="any"
            />
          </div>

          {currentShareholderType === "2" && (
            <div
              className={`d-flex justify-content-center  align-items-center p-3 ${styles.onboarding_info_card}`}
            >
              <InfoIcon />
              <span className=" pl-2">
                {" "}
                This is a corporate shareholder, you are required to add it’s
                ultimate beneficiary.{" "}
              </span>
            </div>
          )}
          <div className=" col-12 ">
            <div className="d-flex justify-content-between flex-wrap">
              <button
                type="submit"
                id="saveAndContinue"
                className="advancly-btn "
              >
                Save Shareholder
                {parentShareholderLoading && <Loader />}
              </button>
              {isAddParentShareholder && shareholdersListData?.length > 0 && (
                <button
                  className="btn advancly-white-btn  transition-3d-hover "
                  type="button"
                  onClick={() => {
                    setIsAddParentShareholder(false);
                    setEditShareholderTable(false);
                    reset();
                  }}
                >
                  Close Shareholder
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShareholderParentForm;
